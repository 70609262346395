import { TypePlatforms, TypeCurrency } from "src/@types";

export const LOADER_SHORT_TIME = 5000;
export const LOADER_LONG_TIME = 10000;
export const CURRENCIES_OPTIONS: TypeCurrency[] = [
  { iso_code: 'amd', symbol: '֏', display_name: 'Դրամ'},
  { iso_code: 'usd', symbol: '$', display_name: 'U.S. Dollar'},
  { iso_code: 'eur', symbol: '€', display_name: 'Euro'},
  { iso_code: 'gbp', symbol: '£', display_name: 'Pound sterling'},
  { iso_code: 'rub', symbol: '₽', display_name: 'Рубль'},
  { iso_code: 'cny', symbol: '¥', display_name: '元'},
];
export const MONTHS_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const DEFAULT_REQUEST_LIST_BY_PLATFORM: {
  platform: TypePlatforms,
  display_name: string,
}[] = [
  { platform: 'REQUEST', display_name: 'PO/Requests' },
  { platform: 'TRANSPORT', display_name: 'TRANSPORT' },
  // { platform: 'DOCUMENT', display_name: 'Contracts/Acts' },
]