/**
 * @description - The file is to define all reducers that are necessary fot the global state.
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// The redux modules in order to provide the reducers
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// All Slices
import {
  newRequestFormReducer,
  platformProjectsReducer,
  companiesProfilesReducer,
  departmentsReducer,
  teamReducer,
  groupsReducer,
  budgetsReducer,
  documentsReducer,
} from './slices';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

// Initial configuration for the redux persistor. This is creating a local storage.
const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// ToDo - clean up codes for the all big data, such as requests, team, documents etc...
const requestPersistConfig = {
  key: 'requests',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

// Defining the root reducers
const rootReducer = combineReducers({
  forms: newRequestFormReducer,
  platformProjects: platformProjectsReducer,
  companiesProfiles: companiesProfilesReducer,
  departments: departmentsReducer,
  team: teamReducer,
  groups: groupsReducer,
  budgets: budgetsReducer,
  documents: documentsReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
