/**
 * @description - The all services that are related to the documents' data
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import { getDocs, addDoc, getDoc, Timestamp, setDoc, updateDoc } from 'firebase/firestore';
// Queries
import {
  queryGetBudgetsListByCompanyUid,
  queryGetActiveBudgetsTotalNumberByCompanyUid,
  getNewBudgetReference,
  queryGetBudgetLinesByBudgetUid,
  getBudgetReference, getBudgetLineReference, getBudgetLineNewReference, getNewBudgetReferenceDoc,
} from './queries';
import { TypeEmployeeProfile } from "src/@types";

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

export type Budget = {
  display_name: string;
  description: string | null;
  uid: string;
  manager_uid: string;
  status: string;
  created: Timestamp;
  updated: Timestamp;
  balance: number;
  unit: 'amd' | 'usd' | 'gbp' | 'rub' | 'eur';
}

export type BudgetLine = {
  display_name: string;
  description: string;
  uid: string;
  status: string;
  balance: number;
  unit: 'amd' | 'usd' | 'gbp' | 'rub' | 'eur';
}

/**
 * @description - The method is getting total number of the budgets
 * @param company_uid
 * @param onSuccess
 * @param onFail
 */
export function fetchBudgetsTotalNumberByCompanyUid(
  company_uid: string,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: Error | string) => void,
) {
  // Generating query
  const queryTotalNumber = queryGetActiveBudgetsTotalNumberByCompanyUid(company_uid);
  if (queryTotalNumber) {
    getDocs(queryTotalNumber)
      .then((snapshotTotalNumber) => onSuccess(snapshotTotalNumber.size))
      .catch((error) => onFail(error.message))
  }
}

export function getStorageLists(
  company_uid: string,
  onSuccess: (totalNumber: number) => void,
  onFail: (error: Error | string) => void,
) {

}

/**
 * @description - The method is fetching budgets list data for the company
 * @param company_uid
 * @param onSuccess
 * @param onFail
 * @param orderByCategory
 * @param categoryOrderSort
 * @param limitNumber
 * @param pageNumber
 */
export function fetchBudgetsByCompanyUid(
  company_uid: string,
  onSuccess: (budgets: Budget[]) => void,
  onFail: (error: Error | string) => void,
  orderByCategory?: string,
  categoryOrderSort?: 'asc' | 'desc',
  limitNumber?: number,
  pageNumber?: number,
) {
  // Generating query
  const queryDetails = queryGetBudgetsListByCompanyUid(company_uid);
  if (queryDetails) {
    getDocs(queryDetails)
      .then((budgetsSnapshots) => {
        const budgets: Budget[] = [];
        budgetsSnapshots.forEach((budgetSnapshot) => {
          const budgetData = budgetSnapshot.data();
          if (budgetData) {
            budgets.push({
              uid: budgetData.uid,
              display_name: budgetData.display_name,
              description: budgetData.description,
              manager_uid: budgetData.manager_uid,
              status: budgetData.status ?? 'suspended',
              created: budgetData.created,
              updated: budgetData.updated,
              balance: budgetData.balance || 0,
              unit: budgetData.unit || 'amd',
            });
          }
        });
        onSuccess(budgets);
      })
      .catch((error) => onFail(error.message));
  }
}

/**
 * @description - The method is fetching budget lines list data for the budget
 * @param budget_uid
 * @param onSuccess
 * @param onFail
 */
export function fetchBudgetLinesByBudgetUid(
  budget_uid: string,
  onSuccess: (budgetLines: BudgetLine[]) => void,
  onFail: (error: Error | string) => void,
) {
  // Generating query
  const queryDetails = queryGetBudgetLinesByBudgetUid(budget_uid);
  if (queryDetails) {
    getDocs(queryDetails)
      .then((budgetLinesSnapshots) => {
        const budgetLines: BudgetLine[] = [];
        budgetLinesSnapshots.forEach((budgetSnapshot) => {
          const budgetLineData = budgetSnapshot.data();
          if (budgetLineData) {
            budgetLines.push({
              uid: budgetLineData.uid,
              display_name: budgetLineData.display_name,
              description: budgetLineData.description,
              status: budgetLineData.status ?? 'suspended',
              balance: budgetLineData.balance || 0,
              unit: budgetLineData.unit || 'usd',
            });
          }
        });
        onSuccess(budgetLines);
      })
      .catch((error) => onFail(error.message));
  }
}

/**
 * @description - The method is fetching budget's detailed view
 * @param budgetUid
 * @param onSuccess
 * @param onFail
 */
export function fetchBudgetByBudgetUid(
  budgetUid: string,
  onSuccess: (budget: Budget) => void,
  onFail: (error: Error | string) => void,
) {
  const queryDetails = getBudgetReference(budgetUid);
  getDoc(queryDetails)
    .then((budgetSnapshot) => {
      if (budgetSnapshot.exists()) {
        const budgetData = budgetSnapshot.data();
        if (budgetData) {
          onSuccess({
            uid: budgetData.uid,
            display_name: budgetData.display_name,
            description: budgetData.description,
            manager_uid: budgetData.manager_uid,
            status: budgetData.status ?? 'suspended',
            created: budgetData.created,
            updated: budgetData.updated,
            balance: budgetData.balance || 0,
            unit: budgetData.unit || 'amd',
          });
        } else {
          onFail(`Something went wrong during fetching budget data ${budgetUid}`);
        }
      } else {
        onFail(`Budget data does not exist ${budgetUid}`);
      }
    })
    .catch((error) => onFail(error.message))
}

export function removeBudgetLine(budgetUid: string, uid: string) {
  const lineReference = getBudgetLineReference(budgetUid, uid);
  updateDoc(lineReference, { active: false, updated: Timestamp.now() }).then(console.log).catch(console.error);
}

export function removeBudget(budgetUid: string) {
  const lineReference = getBudgetReference(budgetUid);
  updateDoc(lineReference, { active: false, updated: Timestamp.now() }).then(() => window.location.reload()).catch(console.error);
}

export function updateBudget(uid: string, data: Record<string, any>) {
  const reference = getBudgetReference(uid);
  getDoc(reference).then((budgetDataSnapshot) => {
    const budgetData = budgetDataSnapshot.data();
    Promise.all([
      setDoc(reference, {
        ...budgetData,
        balance: data.balance,
        description: data.description,
        unit: data.unit,
        display_name: data.display_name,
        status: data.status ?? 'suspended',
        updated: Timestamp.now(),
        manager_uid: data.manager_uid,
      }),
      ...data?.budgetLines?.map((budgetLine: Record<string, any>) => {
        if (budgetLine?.uid) {
          const lineReference = getBudgetLineReference(uid, budgetLine?.uid);
          return setDoc(lineReference, {
            active: budgetLine?.active ?? true,
            balance: budgetLine?.balance ?? 0,
            description: budgetLine?.description ?? '',
            display_name: budgetLine?.display_name ?? '',
            status: budgetLine?.status ?? 'active',
            uid: budgetLine?.uid ?? lineReference.id,
            unit: budgetLine?.unit ?? 'usd',
          });
        } else {
          const lineReference = getBudgetLineNewReference(uid);
          return setDoc(lineReference, {
            active: budgetLine?.active ?? true,
            balance: budgetLine?.balance ?? 0,
            description: budgetLine?.description ?? '',
            display_name: budgetLine?.display_name ?? '',
            status: budgetLine?.status ?? 'active',
            uid: lineReference.id,
            unit: budgetLine?.unit ?? 'usd',
          });
        }
      }),
    ]).then(() => window.location.href = 'https://admin.bisflow.io/app/financial/budgets').catch(console.error)
  });
}

export function createBudget(data: Record<string, any>) {
  const reference = getNewBudgetReferenceDoc();
    if (data?.budgetLines?.length > 0) {
      Promise.all([
        setDoc(reference, {
          active: true,
          company_uid: 'tv1KLFrX60WWGxpI9x1Nkp1w0Wo1',
          description: data.description ?? '',
          uid: reference.id,
          balance: data.balance ?? 0,
          unit: data.unit ?? 'usd',
          display_name: data.display_name,
          status: data.status ?? 'suspended',
          updated: Timestamp.now(),
          created: Timestamp.now(),
          manager_uid: data.manager_uid,
        }),
      ...data?.budgetLines?.map((budgetLine: Record<string, any>) => {
        const lineReference = getBudgetLineNewReference(reference.id);
        return setDoc(lineReference, {
          active: budgetLine?.active ?? true,
          balance: budgetLine?.balance ?? 0,
          description: budgetLine?.description ?? '',
          display_name: budgetLine?.display_name ?? '',
          status: budgetLine?.status ?? 'active',
          uid: lineReference.id,
          unit: budgetLine?.unit ?? 'usd',
        })
      })]).then(() => window.location.href = 'https://admin.bisflow.io/app/financial/budgets').catch(console.error);
    } else {
      setDoc(reference, {
        active: true,
        company_uid: 'tv1KLFrX60WWGxpI9x1Nkp1w0Wo1',
        description: data.description ?? '',
        uid: reference.id,
        balance: data.balance ?? 0,
        unit: data.unit ?? 'usd',
        display_name: data.display_name,
        status: data.status ?? 'suspended',
        updated: Timestamp.now(),
        created: Timestamp.now(),
        manager_uid: data.manager_uid,
      }).then(() => window.location.href = 'https://admin.bisflow.io/app/financial/budgets').catch(console.error);
    }
}
