/**
 * @description - The file contains all common queries in order to fetch necessary data from firestore database
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Firestore
import { getFirestore, query, where, collection } from 'firebase/firestore';
// Paths
import { commonFields } from 'src/configs';

// ================================================================================================================== //
// ====================================================== LOGIC ===================================================== //
// ================================================================================================================== //

/**
 * @description - The method is getting all
 * @param companyUid - The company uid which employee belongs to
 * @param collectionPath - The collection path that need to be
 * @param active - The active status of the query
 */
export function queryTotalNumberByCompanyUid(companyUid: string, collectionPath: string, active = true) {
  // The reference in which the query should do fetching
  const queryDbReference = collection(getFirestore(), collectionPath);
  return query(
    queryDbReference,
    // Checking company based documents
    where(commonFields.FIELD_COMPANY_UID, '==', companyUid),
    // Checking status based documents - processing/template/archive/removed
    where(commonFields.FIELD_ACTIVE, '==', active),
  );
}
