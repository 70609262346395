/**
 * @description - Routes variable for different platforms.
 * Contains list of RouteItem type objects that was passed to
 * renderRoutes method for Switch component
 */

// ================================================================================================================== //
// ===================================================== MODULES ==================================================== //
// ================================================================================================================== //

// Lazy loading is the technique of rendering only-needed or
// critical user interface items first, then quietly unrolling the non-critical
// items later. It is now fully integrated into core react library itself.
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// Paths
import { PATH_APP_DOCS, PATH_APP_AUTH, PATH_APP_MAIN } from './index';
// Types
import { TypeRouteItem } from 'src/@types';
// Components
import { Loadable, AuthGuard } from 'src/components';
import PlatformAccessGuard from 'src/components/guards/PlatformAccessGuard';
// Layouts
import { DashboardLayout } from 'src/layouts';
// Navigation configuration
import { docsNavBarConfig } from './index';
// Wrapper
import DashboardPageWithBreadcrumbs from 'src/layouts/dashboard/views/DashboardPageWithBreadcrumbs';

// ================================================================================================================== //
// ======================================================= PAGES ==================================================== //
// ================================================================================================================== //

// Authentication Pages
const MaintenancePage = Loadable(lazy(() => import('src/pages/maintenance/index')));
const PermissionDeniedPage = Loadable(lazy(() => import('src/pages/access/PagePermissionDenied')));
const DashboardPage = Loadable(lazy(() => import('src/pages/docs/dashboard/DashboardPage')));
const DocumentsListPage = Loadable(lazy(() => import('src/pages/docs/documents/DocumentsListPage')));
const DocumentPageNewEdit = Loadable(lazy(() => import('src/pages/docs/documents/DocumentPageNewEdit')));
const EmailsListPage = Loadable(lazy(() => import('src/pages/docs/communication/EmailsListPage')));
const TemplatesListPage = Loadable(lazy(() => import('src/pages/docs/templates/TemplatesListPage')));
const TemplatePageNewEdit = Loadable(lazy(() => import('src/pages/docs/templates/TemplatePageNewEdit')));
const ContractsPage = Loadable(lazy(() => import('src/pages/contracts/ContractsPage')));

// ================================================================================================================== //
// ===================================================== ROUTES ===================================================== //
// ================================================================================================================== //

/**
 * @description - Bisflow routes list, contains list of RouteItem type
 * objects
 */
const contractRoutes: TypeRouteItem = {
  path: PATH_APP_MAIN.root,
  element: (
    <AuthGuard path={PATH_APP_AUTH.rootFull}>
      <ContractsPage />
    </AuthGuard>
  ),
};

export default contractRoutes;
